import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
  Input,
  Col,
} from 'reactstrap';
import Radio from '../../@vuexy/radio/RadioVuexy';
import { ClipLoader } from 'react-spinners';
import { X } from 'react-feather';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

let color = {
  float: 'left',
  cursor: 'pointer',
  width: '48px',
  height: '48px',
};

let tableShapes = [
  {
    value: 'box',
    label: 'Rectangle',
  },
  { value: 'circle', label: 'Circle' },
  {
    value: 'ovalbend',
    label: 'Oval Bend',
  },
  {
    value: 'ovallong',
    label: 'Oval Long',
  },
];

const TableModal = (props) => {
  const [t, i18n] = useTranslation('global');
  useEffect(() => {
    const lang2 = localStorage.getItem('lang');
    i18n.changeLanguage(lang2);
  }, []);

  const saveAction = () => {
    if (isOpen) {
      submitTable();
    }
  };

  const keydownHandler = (e) => {
    if (e.keyCode === 13 && e.ctrlKey) saveAction();
  };

  useEffect(() => {
    document.addEventListener('keydown', keydownHandler);
    return () => document.removeEventListener('keydown', keydownHandler);
  });

  const dummyTable = {
    table_id: null,
    table_name: '',
    table_status: 1,
    table_color: '#35D374',
    loc_id: props.loc_id,
    position_x: 100,
    position_y: 100,
    table_shape: 'box',
    floor_id: props.floor.id,
    min_capacity: 1,
    max_capacity: 1,
    is_table_available: 1,
  };

  let { isOpen, toggleModal, deleteTable, saveTable, deleting } = props;

  let fl = props.table ? props.table : JSON.parse(JSON.stringify(dummyTable));

  let selectedTableShape = {};
  if (fl.table_id != null) {
    selectedTableShape = tableShapes.map((shape) => {
      return shape.value === fl.table_shape ? shape : null;
    });
  }

  const [table, setTable] = useState(fl);
  const [saving, setSaving] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [maxError, setMaxError] = useState(false);
  const [minError, setMinError] = useState(false);

  let inputRef = React.createRef();

  const updateTable = (key, val) => {
    const fl = JSON.parse(JSON.stringify(table));
    fl[key] = val;
    setTable(fl);
  };

  useEffect(() => {
    let fl = props.table ? props.table : JSON.parse(JSON.stringify(dummyTable));
    isOpen = props.isOpen;
    setSaving(false);
    deleting = props.deleting;
    setTable(fl);
  }, [props]);

  const submitTable = () => {
    if (saving || deleting) return;
    if (table.table_name === '') {
      setNameError(true);
      return;
    }
    if (table.max_capacity === '') {
      setMaxError(true);
      return;
    }
    if (table.min_capacity === '') {
      setMinError(true);
      return;
    }

    const maxCapacity = parseInt(table.max_capacity, 10);
    if (isNaN(maxCapacity) || maxCapacity <= 0) {
      setMaxError(true);
      return;
    }
    setSaving(true);
    saveTable(table);
    setMinError(false);
    setMaxError(false);
    setNameError(false);
  };

  const delTable = () => {
    if (saving || deleting) return;
    deleteTable(table);
  };
  const handleStatusChange = (value) => {
    updateTable('table_status', value);
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        toggle={toggleModal}
        onOpened={() => {
          if (props.table) return;
          setTimeout(() => inputRef.current.focus(), 1);
        }}
        className="modal-dialog-centered d-sm-margin-0 p-0"
        backdrop="static"
      >
        <div className="modal-header display-flex full-width flex-space-between">
          <h5 className="modal-title">
            {props.table
              ? `Edit ${props.table.table_name}`
              : t('add-new-table')}
          </h5>
          <X size={20} onClick={toggleModal} />
        </div>
        <ModalBody>
          <FormGroup>
            <Label for="floor_name">{t('table-name')}: </Label>
            <Input
              innerRef={inputRef}
              type="text"
              id="name"
              value={table.table_name}
              placeholder={t('table-name')}
              invalid={nameError}
              onChange={(e) => updateTable('table_name', e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="table_color" className="mb-1">
              {t('table-color')}:{' '}
            </Label>
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <span
                  style={{
                    ...color,
                    backgroundColor: '#EB6D6D',
                    border: `${table.table_color === '#EB6D6D'
                      ? '1px solid #000'
                      : '#fff'
                      }`,
                  }}
                  role="img"
                  aria-label="Red"
                  title="Red"
                  onClick={() => updateTable('table_color', '#EB6D6D')}
                  key={'82e9ab'}
                ></span>
                <span
                  className="color"
                  style={{
                    ...color,
                    backgroundColor: '#35D374',
                    border: `${table.table_color === '#35D374'
                      ? '1px solid #000'
                      : '#fff'
                      }`,
                  }}
                  role="img"
                  aria-label="Green"
                  title="Green"
                  onClick={() => updateTable('table_color', '#35D374')}
                  key={'8889f2'}
                ></span>
                <span
                  className="color"
                  style={{
                    ...color,
                    backgroundColor: '#6C6DEC',
                    border: `${table.table_color === '#6C6DEC'
                      ? '1px solid #000'
                      : '#fff'
                      }`,
                  }}
                  role="img"
                  aria-label="Purple"
                  title="Purple"
                  onClick={() => updateTable('table_color', '#6C6DEC')}
                  key={'f49595'}
                ></span>
                <span
                  className="color"
                  style={{
                    ...color,
                    backgroundColor: '#EBBF6D',
                    border: `${table.table_color === '#EBBF6D'
                      ? '1px solid #000'
                      : '#fff'
                      }`,
                  }}
                  role="img"
                  aria-label="Orange"
                  title="Orange"
                  onClick={() => updateTable('table_color', '#EBBF6D')}
                  key={'ffd688'}
                ></span>
                <span
                  className="color"
                  style={{
                    ...color,
                    backgroundColor: '#EBEC6D',
                    border: `${table.table_color === '#EBEC6D'
                      ? '1px solid #000'
                      : '#fff'
                      }`,
                  }}
                  role="img"
                  aria-label="Yellow"
                  title="Yellow"
                  onClick={() => updateTable('table_color', '#EBEC6D')}
                  key={'feff9a'}
                ></span>
                <span
                  className="color"
                  style={{
                    ...color,
                    backgroundColor: '#AC6DAD',
                    border: `${table.table_color === '#AC6DAD'
                      ? '1px solid #000'
                      : '#fff'
                      }`,
                  }}
                  role="img"
                  aria-label="Purple"
                  title="Purple"
                  onClick={() => updateTable('table_color', '#AC6DAD')}
                  key={'d1abd2'}
                ></span>
                <span
                  className="color"
                  style={{
                    ...color,
                    backgroundColor: '#6C6D6D',
                    border: `${table.table_color === '#6C6D6D'
                      ? '1px solid #000'
                      : '#fff'
                      }`,
                  }}
                  role="img"
                  aria-label="Grey"
                  title="Grey"
                  onClick={() => updateTable('table_color', '#6C6D6D')}
                  key={'4b4b4b'}
                ></span>
                <span
                  className="color"
                  style={{
                    ...color,
                    backgroundColor: '#ACADAD',
                    border: `${table.table_color === '#ACADAD'
                      ? '1px solid #000'
                      : '#fff'
                      }`,
                  }}
                  role="img"
                  aria-label="Light grey"
                  title="Light grey"
                  onClick={() => updateTable('table_color', '#ACADAD')}
                  key={'d2d2d2'}
                ></span>
                <span
                  className="color"
                  style={{
                    ...color,
                    backgroundColor: '#4ED2BE',
                    border: `${table.table_color === '#4ED2BE'
                      ? '1px solid #000'
                      : '#fff'
                      }`,
                  }}
                  role="img"
                  aria-label="Turquoise"
                  title="Turquoise"
                  onClick={() => updateTable('table_color', '#4ED2BE')}
                  key={'7fddec'}
                ></span>
              </div>
            </div>
          </FormGroup>
          <FormGroup>
            <Label for="table_shape">{t('ADD LOGO SHAPE')}: </Label>
            <Select
              name="pricePerType"
              options={tableShapes}
              defaultValue={selectedTableShape}
              className="React"
              classNamePrefix="select"
              onChange={(selectedOption) => {
                updateTable('table_shape', selectedOption.value);
              }}
            />
          </FormGroup>
          {/* <FormGroup>
            <Label for="min_capacity">{t('min-capacity-0')}: </Label>
            <Input
              type="text"
              id="min_capacity"
              value={table.min_capacity}
              placeholder={t('min-capacity-0')}
              invalid={minError}
              onChange={(e) => updateTable('min_capacity', e.target.value)}
            />
          </FormGroup> */}
          <FormGroup>
            <Label for="max_capacity">{t('max-capacity-0')}: </Label>
            <Input
              type="text"
              id="max_capacity"
              value={table.max_capacity}
              placeholder={t('min-capacity-0')}
              invalid={maxError}
              onChange={(e) => updateTable('max_capacity', e.target.value)}
            />
          </FormGroup>
          <FormGroup className="row">
            <Col md={12}>
              <Label for="table_status">{t('status-0')}: </Label>
              <div
                id="table_status"
                onChange={(e) =>
                  updateTable('table_status', parseInt(e.target.value))
                }
              >
                <div className="d-inline-block mr-1">
                  <Radio
                    label={t('enabled')}
                    checked={table.table_status == 1}
                    name="table_status"
                    value={1}
                    onChange={() => handleStatusChange(1)}
                  />
                </div>
                <div className="d-inline-block mr-1">
                  <Radio
                    label={t('disabled')}
                    name="table_status"
                    checked={table.table_status == 0}
                    value={0}
                    onChange={() => handleStatusChange(0)}
                  />
                </div>
              </div>
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <div className="d-none d-sm-flex flex-space-between full-width">
            <div>
              {props.table && (
                <Button
                  color="darken-1 border-danger"
                  disabled={saving || deleting}
                  onClick={delTable}
                >
                  {deleting ? (
                    <ClipLoader size={15} />
                  ) : (
                    <span className="danger"> {t('delete')} </span>
                  )}
                </Button>
              )}{' '}
            </div>
            <div>
              <Button
                color="dark"
                disabled={saving || deleting}
                onClick={() => {
                  if (saving || deleting) return;
                  toggleModal();
                }}
              >
                {t('cancel')}{' '}
              </Button>{' '}
              <Button
                color="primary"
                disabled={saving || deleting}
                onClick={submitTable}
              >
                {saving ? <ClipLoader size={15} /> : t('save')}
              </Button>{' '}
            </div>
          </div>
          <div className="d-sm-none col-12">
            <Button
              className="col-12"
              color="primary"
              disabled={saving || deleting}
              onClick={submitTable}
            >
              {saving ? <ClipLoader size={15} /> : t('save')}
            </Button>{' '}
            <Button
              className="col-12 my-50"
              color="dark"
              disabled={saving || deleting}
              onClick={() => {
                if (saving || deleting) return;
                toggleModal();
              }}
            >
              {t('cancel')}{' '}
            </Button>{' '}
            {props.table && (
              <Button
                className="col-12"
                color="darken-1"
                disabled={saving || deleting}
                onClick={delTable}
              >
                {deleting ? (
                  <ClipLoader size={15} />
                ) : (
                  <span className="danger"> {t('delete')} </span>
                )}
              </Button>
            )}
          </div>
          <p className="d-none d-sm-inline col-12 align-text-right font-small-1 pr-0">
            {t('hit-ctrl-enter-to-save')}{' '}
          </p>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
export default TableModal;
