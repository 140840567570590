import React, {useState} from "react";
import {Button, Checkbox, Divider, IconButton, Modal, Stack, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ViewHelper from "../../helpers/ViewHelper";
import {green, grey} from "@mui/material/colors";
import SnackBar from "../pos2/components/mainComponents/snackBar";
import {useHistory} from "react-router-dom";

const modalStyle = {
    boxShadow: '0 0 4px rgba(0,0,0,0.25)',
    borderRadius: '0.25rem',
    padding: '1rem',
    backgroundColor: 'white',
    maxWidth: '575px',
    width: '575px'
}

const OrderSplitBillModal = ({showModal, closeModal, orderTax, orderDetails}) => {
    const history = useHistory();
    const [selectedOrderItems, setSelectedOrderItems] = useState([]);
    const [snackbarParams, setSnackbarParams] = useState({
        open: false,
        severity: "",
        message: ""
    })

    console.log("OrderDetails", orderDetails)

    const calculateTotalPrice = (menuPrice, taxPercent, itemCount) => {
        const itemPrice = menuPrice || 0;
        const tax = taxPercent || 0;

        const totalPrice = (itemPrice + (itemPrice * tax) / 100) * itemCount;

        return totalPrice;
    };

    const calculateTotalPriceOptions = (menuPrice, taxPercent) => {
        const itemPrice = menuPrice || 0;
        const tax = taxPercent || 0;

        const totalPrice = itemPrice + (itemPrice * tax) / 100;
        return totalPrice;
    };

    const handleChangeOrder = (menu) => {
        let items = selectedOrderItems;

        const index = items.findIndex(item => item.order_menu_id === menu.order_menu_id);

        if (index === -1) {
            items.push(menu)
        } else {
            items = items.filter(item => item.order_menu_id !== menu.order_menu_id);
        }

        setSelectedOrderItems(items);
    }

    const handlePrintBill = () => {
        console.log(selectedOrderItems);
        if (selectedOrderItems.length <= 0) {
            setSnackbarParams({
                open: true,
                severity: "error",
                message: "Please, Select Items to Split Bill"
            })
        } else {
            history.push({
                pathname: '/splitBillPrint',
                state: {
                    selectedMenu : selectedOrderItems,
                    orderDetails : orderDetails,
                    orderTax : orderTax
                }
            });
        }
    }


    const renderOptions = (order, orderMenuId) => {
        let options = order.OrderOptions.filter(
            (option) => option.order_menu_id === orderMenuId
        );
        return options.map((option) => {
            return (
                <Typography key={option.order_option_id} variant='body2' sx={{fontSize: '0.9rem', color: grey[600], marginLeft: '1rem'}}>
                    {option.quantity} x {option.order_option_name} = {""}
                    {option.order_option_price === 0 ? (
                        `FREE`
                    ) : (
                        <>
                            {ViewHelper.toOptonsCurrency(
                                calculateTotalPriceOptions(
                                    option.order_option_price,
                                    option.order_item_tax_percentage,
                                    // option.order_item_tax,
                                    option.quantity
                                ),
                                // option.order_option_price + option.order_item_tax,
                                orderDetails.Location?.Currency?.currency_code,
                                orderDetails.Location?.decimal_places
                            )}
                        </>
                    )}
                </Typography>
            );
        });
    }

    return(
        <>
            <Modal open={showModal} onClose={closeModal}
                   sx={{
                       display: 'flex',
                       alignItems: 'center',
                       justifyContent: 'center',
                   }}
            >
                <Stack sx={modalStyle}>
                    <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between'}}>
                        <Typography variant='h6'>Split Bill</Typography>
                        <IconButton size={'small'} onClick={closeModal}>
                            <CloseIcon size={'small'}/>
                        </IconButton>
                    </Stack>
                    <Divider />
                    <Stack sx={{paddingY: '0.5rem'}}>
                        {
                            orderDetails?.Orders.map((order) => (
                                order?.OrderMenus.map((menu) => (
                                    <Stack key={menu?.order_menu_id}>
                                        <Stack direction='row' sx={{alignItems: 'center'}}>
                                            <Checkbox onChange={()=> handleChangeOrder(menu)} color={'success'} size={"small"} sx={{marginRight: '0.5rem'}}/>
                                            <Typography variant='body2' sx={{fontSize: '1rem', fontWeight: '600'}}>
                                                {order?.Location?.is_hotel ? (
                                                    <>
                                                        {menu?.name}
                                                    </>
                                                ) : (
                                                    <>
                                                        {menu?.quantity}x {menu?.name} ={" "}
                                                        {ViewHelper.toCurrency(
                                                            calculateTotalPrice(
                                                                menu?.Menu.menu_price,
                                                                menu?.Menu.menu_tax,
                                                                menu?.quantity
                                                            ),

                                                            order?.Location?.Currency?.currency_code,
                                                            order?.Location?.decimal_places
                                                        )}
                                                    </>
                                                )}
                                            </Typography>
                                            <Typography variant='body2' sx={{fontSize: '1rem', fontWeight: '600', marginLeft: 'auto'}}>
                                                {ViewHelper.toCurrency(parseInt(menu.quantity) * menu.price,
                                                    order?.Location?.Currency?.currency_code,
                                                    order?.Location?.decimal_places
                                                )}
                                            </Typography>
                                        </Stack>
                                        {renderOptions(order, menu?.order_menu_id)}
                                    </Stack>
                                ))))}
                    </Stack>
                    <Divider/>
                    <Button
                        onClick={()=> handlePrintBill()}
                        variant={'contained'}
                        sx={{backgroundColor: green[700], marginTop: '0.5rem', '&:hover': { backgroundColor: green[800] } }}
                    >
                        Print Bill
                    </Button>
                </Stack>
            </Modal>
            <SnackBar snackbarParams={snackbarParams} setSnackbarParam={setSnackbarParams}/>
        </>
    )
}

export default OrderSplitBillModal;