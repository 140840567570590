import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import {
  Printer as PrinterIcon,
  Mail,
  Trash,
  Trash2,
  ArrowLeft,
  Cpu,
} from "react-feather";
import SpinnerComponent from "../@vuexy/spinner/Fallback-spinner";
import OrderService from "../../services/OrderService";
import ViewHelper from "../../helpers/ViewHelper";
import TimeHelper from "../../helpers/TimeHelper";
import CONSTANTS from "../../constants";
import "../../assets/css/orders.scss";
import ReactToPrint from "react-to-print";
import PrintOrderSession from "./printOrderSession";
import { updateNavBarNotificationStatus } from "../../redux/actions/notifications";
import ResponseList from "../survey_response/responseList";
import { X } from "react-feather";
import toast, { Toaster } from "react-hot-toast";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import LocationService from "../../services/LocationService";
import { getLocation } from "../../redux/actions/locations";
import ConfirmationModal from "../shared/forms/ConfirmationModal";
import OrderSplitBillModal from "./orderSplitBillModal";

class OrderSessionsDetail extends Component {
  constructor(props) {
    super(props);
    this.componentRef = React.createRef();
  }

  state = {
    loading: true,
    location: null,
    orderSessionDetail: null,
    sessionId: null,
    orderMenuTax: null,
    showPrint: false,
    showEmailModal: false,
    saving: false,
    customerEmail: null,
    showSplitBillModal: false
  };
  async componentDidMount() {
    const savedLanguage = localStorage.getItem("lang");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    const location = LocationService.getLocation();
    console.log(location, ":::::::");
    if (location) {
      await this.refreshData(location.loc_id);
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false, selectLocation: true });
    }
  }

  async refreshData(loc_id, initialize = true, fetchSesionsOrder = true) {
    this.setState({ loading: initialize, refreshing: true });
    if (!this.state.location || loc_id !== this.state.location.loc_id) {
      await this.props.getLocation(loc_id);
    }

    let sessionId = this.props.match.params.id;
    console.log("loc_id", loc_id);
    console.log("sessionId", sessionId);
    let sessionDetail = await OrderService.getSessionsDetail(
        this.props.location.loc_id,
        sessionId
    );

    console.log("SESSIONDETAI", sessionDetail);
    let params = new URLSearchParams(this.props.history.location.search);
    let notification_id = params.get("n"); // is the string "Jonathan"

    if (notification_id != null && loc_id != null) {
      this.props.updateNavBarNotificationStatus(loc_id, notification_id);
    }

    this.setState({
      location: JSON.parse(JSON.stringify(this.props?.location)),
      orderSessionDetail: sessionDetail?.sessionInfo,
      sessionId: sessionDetail?.sessionInfo?.session_id,
      loading: false,
      orderMenuTax: sessionDetail?.order_menu_tax,
    });
  }

  handle = async (id) => {
    try {
      const order = await OrderService.deleteOrderId(id);
      // console.log('Button clicked!', order);
      toast.success("Delete order successfully");
      await this.refreshData(this.props.location.loc_id, false);
      this.setState({
        isDeleteConfirmationModalOpen: false,
        orderIdToDelete: null,
      });
    } catch (error) {
      // console.error('Error handling click event:', error);
      toast.error("Delete order Error ");
    }
  };

  renderOrderDetailViewTop(session) {
    return (
        <div
            className="d-flex flex-column justify-content-center align-items-center mt-2"
            id="order-heading"
        >
          <div className="text-uppercase">
            <p>{this.props.t("session-detail")}</p>
          </div>
          <p className="h4">
            {TimeHelper.toFormattedDate(
                session?.session_date,
                "ddd,Do MMM, YYYY"
            )}
          </p>
          <p className="pt-1">
            {this.props.t("start-time-1")}: {session?.start_time}{" "}
            {this.props.t("end-time-1")}: {session?.end_time}
          </p>

          <p>
            {this.props.t("session-0")}
            {session?.session_id}{" "}
          </p>
        </div>
    );
  }
  calculateTotalPrice = (menuPrice, taxPercent, itemCount) => {
    console.log("tax percent", taxPercent);
    console.log("menuPrice", menuPrice);
    const itemPrice = menuPrice || 0;
    const tax = taxPercent || 0;

    const totalPrice = (itemPrice + (itemPrice * tax) / 100) * itemCount;

    return totalPrice;
  };
  calculateTotalPriceOptions = (menuPrice, taxPercent) => {
    console.log("tax percent", taxPercent);
    console.log("menuPrice", menuPrice);
    const itemPrice = menuPrice || 0;
    const tax = taxPercent || 0;

    const totalPrice = itemPrice + (itemPrice * tax) / 100;
    // const totalPrice = itemPrice + tax;
    console.log("TOTAL_PRICE", totalPrice);
    return totalPrice;
  };

  renderMenuDetails(order, orderMenuTax) {
    console.log("ORDER", order);
    let discounts = 0;
    let subTotal = 0;
    let tax = 0;
    let totals = 0;
    const { isDeleteConfirmationModalOpen, orderIdToDelete } = this.state;
    return (
        <div>
          <ConfirmationModal
              header="Delete Order Item"
              title="Are you sure you want to delete this Order Item?"
              isOpen={isDeleteConfirmationModalOpen}
              accept={() => this.handle(orderIdToDelete)}
              acceptTitle="Yes"
              reject={() =>
                  this.setState({
                    isDeleteConfirmationModalOpen: false,
                    orderIdToDelete: null,
                  })
              }
              rejectTitle="No"
          />
          <Card
              className="col-md-8 col-sm-12 shadow-sm print_order_items"
              style={{ margin: "0px auto" }}
          >
            <CardHeader>{this.props.t("order-items")}</CardHeader>
            <CardBody>
              {order?.Orders.map((singleorder) => {
                const orderTotals = singleorder?.OrderTotals;

                orderTotals.map((order_totals) => {
                  if (order_totals?.code === "cart_total") {
                    subTotal += parseFloat(order_totals?.value);
                  }

                  if (order_totals?.code === "discount") {
                    subTotal += parseFloat(order_totals?.value);
                  }

                  if (order_totals?.code === "tax") {
                    tax += parseFloat(order_totals?.value);
                  }

                  if (order_totals?.code === "order_total") {
                    totals += parseFloat(order_totals?.value);
                  }
                });
                // return singleorder?.OrderMenus.map((menu) => {
                //   console.log("MENU DATA", menu);

                //   // Check if any base_price is 1 in MenuOptions array
                //   const shouldHideMenuName = menu?.Menu?.MenuOptions.some(
                //     (option) => option?.base_price === 1
                //   );

                //   return [
                //     <div
                //       className="order-item mb-1 d-flex justify-content-between"
                //       key={menu?.order_menu_id}
                //     >
                //       {!shouldHideMenuName && (
                //         <div className="f2 name pr-1">
                //           <b>
                //             {menu?.quantity}x {menu?.name} ={" "}
                //             {ViewHelper.toCurrency(
                //               this.calculateTotalPrice(
                //                 menu?.Menu.menu_price,
                //                 menu?.Menu.menu_tax,
                //                 menu?.quantity
                //               ),

                //               order?.Location?.Currency?.currency_code,
                //               order?.Location?.decimal_places
                //             )}
                //           </b>
                //         </div>
                //       )}
                //       {/* <div className="f2 price">
                //         <strong>
                //           {ViewHelper.toCurrency(
                //             parseInt(menu.quantity) * menu.price,
                //             order?.Location?.Currency?.currency_code,
                //             order?.Location?.decimal_places
                //           )}
                //         </strong>
                //         <Trash2
                //           style={{ cursor: "pointer" }}
                //           className="text-danger"
                //           onClick={(e) =>
                //             this.setState({
                //               isDeleteConfirmationModalOpen: true,
                //               orderIdToDelete: menu?.order_id,
                //             })
                //           }
                //         />
                //       </div> */}
                //     </div>,
                //     this.renderOptions(singleorder, menu?.order_menu_id),
                //     shouldHideMenuName ? null : (
                //       <div
                //         className="order-item mb-3 d-flex justify-content-between"
                //         key="comment"
                //       >
                //         {menu.comment && (
                //           <div className="f3 name pr-1">
                //             {this.props?.t("special-instructions")}:{" "}
                //             <span className="font-italic text-muted">
                //               {menu.comment}
                //             </span>
                //           </div>
                //         )}
                //       </div>
                //     ),
                //   ];
                // });

                return singleorder?.OrderMenus.map((menu) => {
                  console.log("MENU DATA", menu);
                  return [
                    <div
                        className="order-item mb-1 d-flex justify-content-between"
                        key={menu?.order_menu_id}
                    >
                      <div className="f2 name pr-1">
                        {order?.Location?.is_hotel ? (
                            <>
                              <b>{menu?.name}</b>
                            </>
                        ) : (
                            <b>
                              {menu?.quantity}x {menu?.name} ={" "}
                              {ViewHelper.toCurrency(
                                  this.calculateTotalPrice(
                                      menu?.Menu.menu_price,
                                      menu?.Menu.menu_tax,
                                      menu?.quantity
                                  ),

                                  order?.Location?.Currency?.currency_code,
                                  order?.Location?.decimal_places
                              )}
                            </b>
                        )}

                        {this.renderOptions(singleorder, menu?.order_menu_id)}
                      </div>
                      <div className="f2 price">
                        <strong>
                          {ViewHelper.toCurrency(
                              parseInt(menu.quantity) * menu.price,
                              order?.Location?.Currency?.currency_code,
                              order?.Location?.decimal_places
                          )}
                        </strong>
                        {/*<Trash2*/}
                        {/*  style={{ cursor: "pointer" }}*/}
                        {/*  className="text-danger"*/}
                        {/*  onClick={(e) =>*/}
                        {/*    this.setState({*/}
                        {/*      isDeleteConfirmationModalOpen: true,*/}
                        {/*      orderIdToDelete: menu?.order_id,*/}
                        {/*    })*/}
                        {/*  }*/}
                        {/*/>*/}
                      </div>
                    </div>,
                    <div
                        className="order-item mb-3 d-flex justify-content-between"
                        key="comment"
                    >
                      {menu.comment && (
                          <div className="f3 name pr-1">
                            {this.props?.t("special-instructions")}:{" "}
                            <span className="font-italic text-muted">
                          {menu.comment}
                        </span>
                          </div>
                      )}
                    </div>,
                  ];
                });
              })}
              <div className="order-item border-top pt-2 d-flex justify-content-between">
                <div className="f2 name pr-1">Total Excl VAT</div>
                <div className="f2 price">
                  {ViewHelper.toCurrency(
                      subTotal,
                      order?.Location?.Currency?.currency_code,
                      order?.Location?.decimal_places
                  )}
                </div>
              </div>

              {/* <div className="order-item d-flex justify-content-between">
            <div className="f2 name pr-1">Discounts</div>
            <div className="f2 price">
              {ViewHelper.toCurrency(
                -discounts,
                order.Location.Currency.currency_code,
                order.Location.decimal_places
              )}
            </div>
          </div> */}
              {orderMenuTax.length > 0
                  ? orderMenuTax.map((menuTax, index) => {
                    return (
                        <div
                            key={index}
                            className="order-item d-flex justify-content-between"
                        >
                          <div className="f2 name pr-1">
                            {order?.Location?.tax_label}{" "}
                            {menuTax?.menu_tax_percentage}%
                          </div>
                          <div className="f2 price">
                            {ViewHelper.toCurrency(
                                menuTax?.total_menu_tax,
                                // tax,
                                order?.Location?.Currency?.currency_code,
                                order?.Location?.decimal_places
                            )}
                          </div>
                        </div>
                    );
                  })
                  : null}

              {/* <div className="order-item d-flex justify-content-between border-bottom pb-2 mt-2">
            <div className="f2 name pr-1">
              {this.props.t('total-0')} {order?.Location?.tax_label}
            </div>
            <div className="f2 price">
              {ViewHelper.toCurrency(
                tax,
                order?.Location?.Currency?.currency_code,
                order?.Location?.decimal_places
              )}
            </div>
          </div> */}

              <div className="order-item d-flex justify-content-between pt-1">
                <div className="f2 name pr-1">
                  <strong>{this.props.t("total-0")}</strong>
                </div>
                <div className="f2 price">
                  <strong>
                    {ViewHelper.toCurrency(
                        totals,
                        order?.Location?.Currency?.currency_code,
                        order?.Location?.decimal_places
                    )}
                  </strong>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
    );
  }

  renderOptions(order, orderMenuId) {
    let options = order.OrderOptions.filter(
        (option) => option.order_menu_id === orderMenuId
    );
    return options.map((option) => {
      console.log("OPTIONSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS", option);
      return (
          <div
              key={option.order_option_id}
              className="text-muted text-small pl-1"
          >
            {/* {option.display_type ===
          CONSTANTS.VARIABLES.MENUS.OPTIONS.DISPLAY_TYPES.SELECT
            ? `${option.quantity}x `
            : ''} */}
            {option.quantity}x{option.order_option_name} = {""}
            {option.order_option_price === 0 ? (
                <span>FREE</span>
            ) : (
                <span>
              {ViewHelper.toOptonsCurrency(
                  this.calculateTotalPriceOptions(
                      option.order_option_price,
                      option.order_item_tax_percentage,
                      // option.order_item_tax,
                      option.quantity
                  ),
                  // option.order_option_price + option.order_item_tax,
                  this.state.orderSessionDetail.Location?.Currency?.currency_code,
                  this.state.orderSessionDetail.Location?.decimal_places
              )}
            </span>
            )}
          </div>
      );
    });
  }

  renderOrderDetails(order) {
    console.log("Order", order);
    return (
        <Card
            className="col-md-8 col-sm-12 shadow-sm"
            style={{ margin: "0px auto" }}
        >
          <CardHeader>{this.props.t("customer-and-order-detail")}</CardHeader>
          <CardBody>
            <div className="mb-2 order-item mb-1 d-flex justify-content-between">
              <div>
                {order.table_name ? (
                    <strong>{this.props.t("table-name")}</strong>
                ) : (
                    <strong>Room Name</strong>
                )}
              </div>
              <div>
                {order.table_name ? (
                    <span>{order.table_name}</span>
                ) : (
                    <span>{order.room_name}</span>
                )}
              </div>
            </div>

            <div className="mb-2 order-item mb-1 d-flex justify-content-between">
              <div>
                <strong>{this.props.t("session-status")}</strong>
              </div>
              <div>{order?.session_status.toUpperCase()}</div>
            </div>
            <div className="mb-2 order-item mb-1 d-flex justify-content-between">
              <div>
                <strong>{this.props.t("location-name")}</strong>
              </div>
              <div>{order?.Location.name}</div>
            </div>
          </CardBody>
        </Card>
    );
  }

  closeEmailSendModal() {
    this.setState({
      saving: false,
      showEmailModal: false,
      customerEmail: null,
    });
  }

  async sendOrderReceipt() {
    try {
      this.setState({ saving: true });
      let loc_id = this.state.orderSessionDetail.Location.loc_id;
      let session_id = this.state.orderSessionDetail.session_id;

      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailRegex.test(this.state.customerEmail)) {
        toast.error(this.props.t("invalid-email-address"));
        this.setState({ saving: false });
        return;
      }

      let sendEmail = await OrderService.sendReceiptToCustomer(
          loc_id,
          session_id,
          this.state.customerEmail
      );
      toast.success(this.props.t("receipt-send-successfully"));
      this.closeEmailSendModal();
    } catch (e) {
      this.closeEmailSendModal();
      toast.error(e.toString());
      return;
    }
  }

  handleSendToCarsine = async () => {
    try {
      const session_id = this.state.sessionId;
      const response = await LocationService.getSessionCarsine(session_id);
      if (response.error) {
        toast.error(response.error);
      } else {
        toast.success("Order Sent to Caresine");
      }
    } catch (error) {
      toast.error("Error fetching session carsine: " + error.message);
    }
  };

  handleSplitBillModalClose = () => {
    this.setState({ showSplitBillModal: false });
  }

  // calculateTotalPrice = (menuPrice, taxPercent) => {
  //   const itemPrice = menuPrice || 0;
  //   const tax = taxPercent || 0;
  //   return itemPrice + (itemPrice * tax) / 100;
  // };
  render() {
    const { t } = this.props;
    const {
      state: { orderSessionDetail, loading, orderMenuTax },
    } = this;
    console.log("ORDERSESSIONDETAIL", orderSessionDetail);

    if (loading) return <SpinnerComponent />;
    return (
        <>
          <Toaster position="top-right" />
          <ReactToPrint
              trigger={() => {
                // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                // to the root node of the returned component as it will be overwritten.
                return (
                    <Button className="btn-icon" color="primary">
                      <PrinterIcon size={15} /> {t("print-session-data")}
                    </Button>
                );
              }}
              content={() => this.componentRef.current}
              pageStyle={
                "@page { size: 80mm 297mm; } @media print { body { -webkit-print-color-adjust: white; padding: 40px !important; width: 8cm; height: 100%; margin: 0; } }"
              }
          />
          <Button
              className="btn-icon ml-1"
              color="secondary"
              onClick={() => this.setState({ showEmailModal: true })}
          >
            <Mail size={15} /> {t("send-receipt-in-mail")}
          </Button>
          <Button
              className="btn-icon ml-1"
              color="primary"
              onClick={() => this.handleSendToCarsine()}
              // disabled={orderSessionDetail.session_status !== "completed"}
          >
            <Cpu size={15} /> Send to Caresine
          </Button>

          <Button className={"btn-icon ml-1"} color={'primary'} onClick={()=> this.setState({showSplitBillModal : true })}>
            Split Bill
          </Button>

          <div className="">
            {this.renderOrderDetailViewTop(orderSessionDetail)}
            <Row>
              <Col md="12" sm="12">
                {this.renderMenuDetails(orderSessionDetail, orderMenuTax)}
              </Col>
              <Col md="12" sm="12">
                {this.renderOrderDetails(orderSessionDetail)}
              </Col>
            </Row>
          </div>
          <div className="row mt-4">
            <div className="col-md-12">
              <ResponseList
                  surveyResponse={orderSessionDetail?.LocationSurveyResponses}
                  hideSessionbtn={true}
              />
            </div>
            <React.Fragment>
              <Modal
                  isOpen={this.state.showEmailModal}
                  className="modal-dialog-centered d-sm-margin-0 p-0"
                  backdrop="static"
              >
                <div className="modal-header display-flex full-width flex-space-between">
                  <h5 className="modal-title">
                    {t("share-receipt-with-customer")}
                  </h5>
                  <X
                      className="cursor-pointer"
                      size={20}
                      onClick={() => this.closeEmailSendModal()}
                  />
                </div>

                <ModalBody>
                  <FormGroup className="row">
                    <Col md={12}>
                      <Label for="question">{t("customer-email")}: </Label>
                      <Input
                          type="email"
                          id="email"
                          className="mt-2"
                          placeholder={t("survey-question")}
                          onChange={(e) =>
                              this.setState({ customerEmail: e.target.value })
                          }
                          autoComplete="off"
                          autoFocus={true}
                      />
                    </Col>
                  </FormGroup>
                </ModalBody>
                <ModalFooter>
                  <div className="d-none d-sm-flex flex-space-between full-width">
                    <div>
                      {/* <Button
                      color="dark white"
                      onClick={() => this.closeEmailSendModal()}
                    >
                      {t('cancel')}{' '}
                    </Button>{' '} */}
                      <Button
                          color="primary"
                          onClick={() => this.sendOrderReceipt()}
                          disabled={this.state.saving}
                      >
                        {this.state.saving ? t("sending") : t("send-email-0")}
                      </Button>{" "}
                    </div>
                  </div>
                </ModalFooter>
              </Modal>
            </React.Fragment>
          </div>
          <div style={{ display: "none" }}>
            <PrintOrderSession
                componentRef={this.componentRef}
                sessionId={this.props.match.params.id}
                location_id={this.props.location.loc_id}
            />
          </div>
          {this.state.showSplitBillModal &&
              <OrderSplitBillModal
                  showModal={this.state.showSplitBillModal}
                  closeModal={this.handleSplitBillModalClose}
                  orderTax={this.state.orderMenuTax}
                  orderDetails={this.state.orderSessionDetail}
              /> }
        </>
    );
  }
}

function mapStateToProps(state) {
  const { sessionslist } = state.orders;
  const { location } = state.locations;
  return {
    sessionslist: sessionslist?.data?.data ? sessionslist?.data?.data : [],
    totalCount: sessionslist?.data?.count ? sessionslist?.data?.count : 0,
    loading: sessionslist.loading || location.loading,
    location: location.data,
  };
}
export default withTranslation("global")(
    connect(mapStateToProps, {
      getLocation,
      updateNavBarNotificationStatus,
    })(OrderSessionsDetail)
);
