import React, {useEffect, useRef} from 'react';
import {useReactToPrint} from "react-to-print";
import {useHistory, useLocation} from "react-router-dom";
import SplitBillPrintableComponent from "./splitBillPrintableComponent";
import {Button} from "@mui/material";
import {green} from "@mui/material/colors";

const SplitBillPrint = () => {
    const history = useHistory();
    const location = useLocation();
    const {selectedMenu, orderDetails, orderTax} = location.state;
    const printRef = useRef();

    const checkDataAndPrint = () => {
        if (!selectedMenu || !orderDetails || !orderTax || selectedMenu.length <= 0) {
            history.push("/sessions");
        }

        printBill();
    }

    const printBill = useReactToPrint({
        content: () => printRef.current,
        pageStyle: `
          @page { size: 80mm 297mm; } 
          @media print { 
            body { 
              -webkit-print-color-adjust: exact; 
              padding: 40px !important; 
              width: 8cm; 
              height: 100%; 
              margin: 0; 
            } 
          }
        `,
    })

    useEffect(() => {
        checkDataAndPrint();
    }, []);

    const handleGoBackToSessionDetail = () => {
        history.push(`/sessions/${orderDetails.session_id}`)
    }

    return (
        <>
            <Button
                onClick={()=> handleGoBackToSessionDetail()}
                variant='contained'
                sx={{
                backgroundColor: green[700],
                '&:hover': {
                    backgroundColor: green[800]
                },
                    margin: '1rem',
                    width: 'fit-content'
            }}>
                Session Detail
            </Button>
            <SplitBillPrintableComponent ref={printRef} selectedItems={selectedMenu} orderDetails={orderDetails} orderTax={orderTax}/>
        </>
    );
};

export default SplitBillPrint;