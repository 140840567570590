import React from "react";
import {Box, Stack, Typography} from "@mui/material";
import TimeHelper from "../../../helpers/TimeHelper";
import {useTranslation} from "react-i18next";
import ViewHelper from "../../../helpers/ViewHelper";
import CONSTANTS from "../../../constants";

const SplitBillPrintableComponent = React.forwardRef((props, ref) => {
    const {t} = useTranslation('global');

    const calculateTotalPriceOptions = (menuPrice, taxPercent) => {
        const itemPrice = menuPrice || 0;
        const tax = taxPercent || 0;

        const totalPrice = itemPrice + (itemPrice * tax) / 100;
        // const totalPrice = itemPrice + tax;
        return totalPrice;
    };

    const renderOptions = (order, orderMenuId) => {
        let options = order.OrderOptions.filter(
            (option) => option.order_menu_id === orderMenuId
        );
        return options.map((option) => {
            return (
                <div key={option?.order_option_id} className="text-small pl-1">
                    {option?.display_type ===
                    CONSTANTS.VARIABLES.MENUS.OPTIONS.DISPLAY_TYPES.SELECT
                        ? `${option?.quantity}x `
                        : ""}
                    {option.order_option_name}{" "}
                    {ViewHelper.toCurrency(
                        // option.order_option_price,
                        calculateTotalPriceOptions(
                            option.order_option_price,
                            option.order_item_tax_percentage,
                            // option.order_item_tax,
                            option.quantity
                        ),
                        props.orderDetail?.Location?.Currency.currency_code,
                        props.orderDetail?.Location?.decimal_places
                    )}
                </div>
            );
        });
    }

    const renderMenuDetails = (order, orderMenuTax) => {
        let discounts = 0;
        let subTotal = 0;
        let tax = 0;
        let totals = 0;
        return (
            <>
                <Typography variant={'h6'} sx={{fontSize: '1rem', fontWeight: '600'}}>
                    {t("order-items")}
                </Typography>
                <>
                    {order?.Orders.length > 0
                        ? order.Orders.map((singleorder) => {
                            const orderTotals = singleorder.OrderTotals;
                            orderTotals.map((order_totals) => {
                                if (order_totals?.code === "cart_total") {
                                    subTotal += parseFloat(order_totals?.value);
                                }

                                if (order_totals?.code === "discount") {
                                    subTotal += parseFloat(order_totals?.value);
                                }

                                if (order_totals?.code === "tax") {
                                    tax += parseFloat(order_totals?.value);
                                }

                                if (order_totals?.code === "order_total") {
                                    totals += parseFloat(order_totals?.value);
                                }
                            });
                            return singleorder.OrderMenus.map((menu) => {
                                const isSelected = props.selectedItems.some(
                                    (selected) => selected.order_menu_id === menu.order_menu_id
                                );

                                if (!isSelected) {
                                    const menuPrice = ((parseInt(menu.quantity) *
                                            menu?.price *
                                            menu?.menu_tax_percentage) /
                                        100) +
                                        (menu?.price * menu.quantity);

                                    const st = menu?.price * menu.quantity;
                                    const t = ((parseInt(menu.quantity) *
                                            menu?.price *
                                            menu?.menu_tax_percentage) /
                                        100)

                                    tax -= t;
                                    totals -= menuPrice;
                                    subTotal -= st;
                                }

                                if (isSelected) {
                                    return [
                                        <div
                                            className="order-item mb-1 d-flex justify-content-between"
                                            key={menu.order_menu_id}
                                        >
                                            <div className="f2 name pr-1">
                                                <b>
                                                    {menu.quantity}x {menu.name}
                                                </b>
                                                {renderOptions(singleorder, menu.order_menu_id)}
                                            </div>
                                            <div className="f2 price">
                                                <strong>
                                                    {ViewHelper.toCurrency(
                                                        (parseInt(menu.quantity) *
                                                            menu?.price *
                                                            menu?.menu_tax_percentage) /
                                                        100 +
                                                        menu?.price * menu.quantity,
                                                        order?.Location?.Currency?.currency_code,
                                                        order?.Location?.decimal_places
                                                    )}
                                                </strong>
                                            </div>
                                        </div>,
                                        <div
                                            className="order-item mb-3 d-flex justify-content-between"
                                            key="comment"
                                        >
                                            {menu?.comment && (
                                                <div className="f3 name pr-1">
                                                    {t("special-instructions")}:{" "}
                                                    <span className="font-italic">{menu?.comment}</span>
                                                </div>
                                            )}
                                        </div>,
                                    ];
                                }
                            });
                        })
                        : null}
                    <div className="order-item border-top pt-2 d-flex justify-content-between">
                        <div className="f2 name pr-1">{t("sub-total")}</div>
                        <div className="f2 price">
                            {ViewHelper.toCurrency(
                                subTotal,
                                order?.Location?.Currency?.currency_code,
                                order?.Location?.decimal_places
                            )}
                        </div>
                    </div>

                    {/*{orderMenuTax.length > 0*/}
                    {/*    ? orderMenuTax.map((menuTax, index) => {*/}
                    {/*        return (*/}
                    {/*            <div*/}
                    {/*                key={index}*/}
                    {/*                className="order-item d-flex justify-content-between"*/}
                    {/*            >*/}
                    {/*                <div className="f2 name pr-1">*/}
                    {/*                    {order?.Location?.tax_label}{" "}*/}
                    {/*                    {menuTax?.menu_tax_percentage}%*/}
                    {/*                </div>*/}
                    {/*                <div className="f2 price">*/}
                    {/*                    {ViewHelper.toCurrency(*/}
                    {/*                        menuTax?.total_menu_tax,*/}
                    {/*                        order?.Location?.Currency?.currency_code,*/}
                    {/*                        order?.Location?.decimal_places*/}
                    {/*                    )}*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        );*/}
                    {/*    })*/}
                    {/*    : null}*/}

                    <div className="order-item d-flex justify-content-between border-bottom pb-2">
                        <div className="f2 name pr-1">
                            {order?.Location?.tax_label} {t("total-0")}
                        </div>
                        <div className="f2 price">
                            {ViewHelper.toCurrency(
                                tax,
                                order?.Location?.Currency?.currency_code,
                                order?.Location?.decimal_places
                            )}
                        </div>
                    </div>

                    <div className="order-item d-flex justify-content-between pt-1">
                        <div className="f2 name pr-1">
                            <strong>{t("total-0")}</strong>
                        </div>
                        <div className="f2 price">
                            <strong>
                                {ViewHelper.toCurrency(
                                    totals,
                                    order?.Location?.Currency?.currency_code,
                                    order?.Location?.decimal_places
                                )}
                            </strong>
                        </div>
                    </div>
                </>
            </>
        );
    }

    return (
            <Box ref={ref} sx={{width: '80mm'}}>
                <Stack sx={{alignItems: 'center', justifyContent: 'center', rowGap: '1rem'}}>
                    <Typography variant={'h6'}>
                        {t("session-detail")}
                    </Typography>
                    <Typography variant='h6' >
                        {TimeHelper.toFormattedDate(
                            props?.orderDetails.session_date,
                            "ddd,Do MMM, YYYY"
                        )}
                    </Typography>
                    <Stack>
                        {t("start-time-1")}: {props.orderDetails?.start_time}{" "}
                        {t("end-time-1")}: {props.orderDetails?.end_time}
                    </Stack>
                    <Typography variant='h6' sx={{fontSize: '1.1rem'}}>
                        {t("session-0")}
                        {props.orderDetails?.session_id}{" "}
                    </Typography>
                </Stack>
                {renderMenuDetails(props.orderDetails, props.orderTax)}
            </Box>
        )
    }
)

export default SplitBillPrintableComponent;